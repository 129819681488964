var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bar-container"},[_c('div',{staticStyle:{"width":"100%"}},[_c('el-select',{staticClass:"charts",attrs:{"placeholder":"请选择"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.options1),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('div',{staticClass:"body"},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","width":"72%"}},[_c('div',{style:(("overflow: hidden;height:" + (_vm.value == 1 ? '425px' : 0) + ";width: 100%;"))},[_c('div',{staticClass:"barMain",staticStyle:{"height":"425px","width":"100%"},attrs:{"id":_vm.barMain[0]}})]),_c('div',{style:(("overflow: hidden;height:" + (_vm.value == 2 ? '425px' : 0) + ";"))},[_c('div',{staticClass:"barMain",staticStyle:{"height":"425px"},attrs:{"id":_vm.barMain[1]}})])]),(_vm.barMain[0] == 'pro1')?_c('div',{staticClass:"shopNum"},[_c('div',{staticClass:"publicTit"},[_vm._v("项目服务中的各平台店铺数")]),_c('ul',{staticClass:"con"},[_c('li',{staticClass:"con-item"},[_vm._m(0),_c('el-button',{staticClass:"pubTextBtn",attrs:{"type":"text"},on:{"click":function () {
                  _vm.collect(
                    'taobaoShopNum',
                    '淘宝服务中店铺数',
                    [
                      {
                        name: '所属客户',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                      { name: '所属客户', colProp: ['customerName'] },
                      { name: '服务时间', colProp: ['startDate', 'endDate'] }
                    ]
                  );
                }}},[_vm._v(_vm._s(_vm.rightData.taobaoShopNum))])],1),_c('li',{staticClass:"con-item"},[_vm._m(1),_c('el-button',{staticClass:"pubTextBtn",attrs:{"type":"text"},on:{"click":function () {
                  _vm.collect(
                    'tianMaoShopNum',
                    '天猫服务中店铺数',
                    [
                      {
                        name: '所属客户',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                      { name: '所属客户', colProp: ['customerName'] },
                      { name: '服务时间', colProp: ['startDate', 'endDate'] }
                    ]
                  );
                }}},[_vm._v(_vm._s(_vm.rightData.tianMaoShopNum))])],1),_c('li',{staticClass:"con-item"},[_vm._m(2),_c('el-button',{staticClass:"pubTextBtn",attrs:{"type":"text"},on:{"click":function () {
                  _vm.collect(
                    'JDShopNum',
                    '京东服务中店铺数',
                    [
                      {
                        name: '所属客户',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                      { name: '所属客户', colProp: ['customerName'] },
                      {
                        name: '服务时间',
                        colProp: ['startDate', 'endDate']
                      }
                    ]
                  );
                }}},[_vm._v(_vm._s(_vm.rightData.JDShopNum))])],1),_c('li',{staticClass:"con-item"},[_vm._m(3),_c('el-button',{staticClass:"pubTextBtn",attrs:{"type":"text"},on:{"click":function () {
                  _vm.collect(
                    'pinduoduoShopNum',
                    '拼多多服务中店铺数',
                    [
                      {
                        name: '所属客户',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                      { name: '所属客户', colProp: ['customerName'] },
                      {
                        name: '服务时间',
                        colProp: ['startDate', 'endDate']
                      }
                    ]
                  );
                }}},[_vm._v(_vm._s(_vm.rightData.pinduoduoShopNum))])],1),_c('li',{staticClass:"con-item"},[_vm._m(4),_c('el-button',{staticClass:"pubTextBtn",attrs:{"type":"text"},on:{"click":function () {
                  _vm.collect(
                    'tiktokShopNum',
                    '抖音服务中店铺数',
                    [
                      {
                        name: '所属客户',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                      { name: '所属客户', colProp: ['customerName'] },
                      {
                        name: '服务时间',
                        colProp: ['startDate', 'endDate']
                      }
                    ]
                  );
                }}},[_vm._v(_vm._s(_vm.rightData.tiktokShopNum))])],1),_c('li',{staticClass:"con-item"},[_vm._m(5),_c('el-button',{staticClass:"pubTextBtn",attrs:{"type":"text"},on:{"click":function () {
                  _vm.collect(
                    'quickWorkerShopNum',
                    '快手服务中店铺数',
                    [
                      {
                        name: '所属客户',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                      { name: '所属客户', colProp: ['customerName'] },
                      {
                        name: '服务时间',
                        colProp: ['startDate', 'endDate']
                      }
                    ]
                  );
                }}},[_vm._v(_vm._s(_vm.rightData.quickWorkerShopNum))])],1),_c('li',{staticClass:"con-item"},[_vm._m(6),_c('el-button',{staticClass:"pubTextBtn",attrs:{"type":"text"},on:{"click":function () {
                  _vm.collect(
                    'otherShopNum',
                    '其他平台服务中店铺数',
                    [
                      {
                        name: '所属客户',
                        type: 'input',
                        modelKey: 'customerName'
                      }
                    ],
                    [
                      { name: '店铺名称', colProp: ['shopName'], url: '/customer/company-shop-list', paramsArr: [{urlKey: 'shopName', paramsKey: 'shopName'}, {urlKey: 'shopId', paramsKey: 'shopId'}] },
                      { name: '所属客户', colProp: ['customerName'] },
                      {
                        name: '服务时间',
                        colProp: ['startDate', 'endDate']
                      }
                    ]
                  );
                }}},[_vm._v(_vm._s(_vm.rightData.otherWorkerShopNum))])],1)])]):_c('div',{staticClass:"shopNum2"},[_c('ul',[_c('li',{staticStyle:{"display":"flex","align-items":"center"}},[_c('span',{staticClass:"left"},[_vm._v("累计收入")]),_c('span',{staticClass:"right"},[_c('span',{staticClass:"mon"},[_vm._v("本年度收入总金额")]),_c('el-button',{staticClass:"money",attrs:{"type":"text"},on:{"click":function () {
                    _vm.collect(
                      'incomeTotal',
                      '本年度收入总金额',
                      [],
                      [
                        { name: '流水编号', colProp: ['code'] },
                        { name: '收入金额', colProp: ['amount'] },
                        { name: '收入状态', colProp: ['status'] },
                        { name: '收入类型', colProp: ['expenditureTypeName'] },
                        { name: '收款人', colProp: ['transactionName'] },
                        { name: '收入方式', colProp: ['recordType'] },
                        { name: '收入账户', colProp: ['transactionAccount'] },
                        { name: '备注', colProp: ['remark'] }
                      ],
                      'income'
                    );
                  }}},[_vm._v("￥"+_vm._s(_vm.rightData.incomeTotal.toFixed(2)))])],1)]),_c('li',{staticStyle:{"display":"flex","align-items":"center"}},[_c('span',{staticClass:"left2"},[_vm._v("累计支出")]),_c('span',{staticClass:"right2"},[_c('span',{staticClass:"mon"},[_vm._v("本年度支出总金额")]),_c('el-button',{staticClass:"money",attrs:{"type":"text"},on:{"click":function () {
                    _vm.collect(
                      'expenditureTotal',
                      '本年度支出总金额',
                      [],
                      [
                        { name: '流水编号', colProp: ['code'] },
                        { name: '出账金额', colProp: ['amount'] },
                        { name: '出账状态', colProp: ['status'] },
                        { name: '出账类型', colProp: ['expenditureType'] },
                        { name: '收款人', colProp: ['transactionName'] },
                        { name: '出账方式', colProp: ['recordType'] },
                        { name: '出账账户', colProp: ['transactionAccount'] },
                        { name: '备注', colProp: ['remark'] }
                      ],
                      'payout'
                    );
                  }}},[_vm._v("￥"+_vm._s(_vm.rightData.expenditureTotal.toFixed(2)))])],1)]),_c('li',{staticStyle:{"display":"flex","align-items":"center"}},[_c('span',{staticClass:"left"},[_vm._v("利润")]),_c('span',{staticClass:"right"},[_c('span',{staticClass:"mon"},[_vm._v("毛利润总金额")]),_c('span',{staticClass:"money3"},[_vm._v("￥"+_vm._s(_vm.rightData.profitMonthTotal.toFixed(2)))])])])])])])],1),_c('common-sum-dialog',{ref:"commonSumDialog",attrs:{"methodFuc":_vm.barMain[0] == 'pro1' ? _vm.getMarketReportList : _vm.getFinanceReportDetail,"commonSumVisible":_vm.commonSumVisible},on:{"handleClose":_vm.sumVisibleClose}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/TB.png")}}),_c('span',[_vm._v("淘宝店铺数")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/TM.png")}}),_c('span',[_vm._v("天猫店铺数")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/JD.png")}}),_c('span',[_vm._v("京东店铺数")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/PXX.png")}}),_c('span',[_vm._v("拼多多店铺数")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/DY.png")}}),_c('span',[_vm._v("抖音铺数")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/KS.png")}}),_c('span',[_vm._v("快手店铺数")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/Other.png")}}),_c('span',[_vm._v("其他平台店铺数")])])}]

export { render, staticRenderFns }